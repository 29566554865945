@use './_variables' as *;

/* Sub-mixins need to be outside the primary mixin */
@mixin crisp-scaled-down {
  image-rendering: -moz-crisp-edges; // Firefox
  image-rendering: -o-crisp-edges; // Opera
  image-rendering: -webkit-optimize-contrast; // Webkit (non-standard naming)
  image-rendering: crisp-edges;
}

.item .item-press {
  max-width: 40.625rem;
  margin: auto auto .75rem;

  span:last-of-type {
    white-space: nowrap;

    a img {
      position: relative;
      top: .375rem;
      max-height: 1.5rem;
    }
  }
}

.item {
  width: $page-width;
  margin-bottom: 1rem;

  .feature-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: $border-width-small solid $twenty-percent-gray;
    padding-bottom: 1.25rem;
  }

  .feature-image {
    @include crisp-scaled-down;

    width: $viewport-width-ninety-percent;
    max-width: $image-dimension-xtra-large;
    vertical-align: top;

    &.postcard {
      border: $border-standard;
      max-width: $image-dimension-large;

      &.cachalot {
        border-radius: $border-radius-xtra-large;
      }
    }

    &.print {
      max-width: 450px;
    }
  }

  .item-info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: $image-dimension-large;
  }

  .item-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin: .5rem auto;

    span.normal {
      font-weight: 400;
    }
  }

  .item-info-container {
    padding-inline-start: .75rem;
    margin: .5rem;
  }

  .payment-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .125rem auto .5rem;
  }

  .item-price-container {
    position: relative;
    top: .75rem;
    margin-right: .5rem;
    font-size: 1.375rem;
    font-weight: 600;
  }

  .quantity-label {
    margin-left: .5rem;
    font-size: .875rem;
  }

  form {
    display: flex;
    align-items: stretch;

    input[name="quantity"] {
      width: 2.5rem;
      min-height: 2.25rem;
      padding: 0;
      border: $border-width-small solid $fifty-percent-gray;
      margin-right: .5rem;
      font-size: .75rem;
      text-align: center;
    }

    input[value="Add to cart"] {
      width: 10rem;
      border: none;
      border-radius: $border-radius-small;
      background-color: $sixty-seven-percent-gray;
      color: $white;
      font-family: "Raleway", sans-serif;
      font-size: .875rem;
      font-weight: 600;
      letter-spacing: .125rem;
      cursor: pointer;

      &:hover {
        border: $border-width-medium solid $sixty-seven-percent-gray;
        color: $black;
        background-color: $rainbow-yellow;
      }
    }
  }

  .validation-message {
    color: $red;
    font-size: .875rem;
  }

  .detail-information .detail-image-pair {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin: 1rem .5rem auto;
      max-width: $image-dimension-medium;
      width: $viewport-width-ninety-percent;
      max-height: $image-dimension-medium;
      height: $viewport-width-ninety-percent;

      &.lunar-calendar.recent-lunar-calendar {
        max-width: $image-dimension-xtra-large;
        max-height: $image-dimension-xtra-large;
      }
    }
  }

  .item-description {
    max-width: $character-width;
    padding-top: 1rem;
    margin: auto;
  }
}

/* Media Queries */

@media (min-width: $breakpoint-sm) {

  .item .feature-image {
    width: 80vw;
  }
}

@media (min-width: $breakpoint-md) {

  .item .feature-image,
  .item .detail-information .detail-image-pair img {
    width: initial;
  }
}

@media (min-width: $breakpoint-lg) {

  .item {

    .item-info-block {
      flex-direction: column-reverse;
      padding-left: 1rem;
    }

    .payment-info {
      flex-direction: column;
      align-items: flex-start;
      margin: initial;
    }

    .item-price-container {
      top: initial;
      margin-bottom: 1rem;
    }

    .feature-info {
      flex-direction: row;
      align-items: stretch;
    }

    .detail-information .detail-image-pair {
      flex-direction: row;

      img {
        width: $image-dimension-medium;
        height: $image-dimension-medium;
      }
    }
  }
}

@media (min-width: $breakpoint-xl) {

  .item .detail-information .detail-image-pair img {
    width: 38vw;
    height: 38vw;
  }
}
