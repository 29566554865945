@use './variables' as *;

.moon-space {
  width: $moon-space-dimension;
  max-height: 450px;
}

.news-container {
  margin-bottom: .5rem;
}

.moon-image-container {
  overflow: hidden;
  height: $moon-space-dimension;
  margin: auto;
}

.cloud {
  z-index: 1;
  opacity: 1;
  position: relative;
  transition: opacity 1.5s;

  &.is-not-visible {
    opacity: 0;
  }
}

.cloud-top {
  bottom: 200px;
  width: 140px;
  animation: 10s linear infinite cloud-top-frames;
}

@keyframes cloud-top-frames {
  from { transform: translateX(-140px); }
  to { transform: translateX($moon-space-dimension); }
}

.cloud-middle {
  bottom: 286px;
  width: 160px;
  animation: 20s linear infinite cloud-middle-frames;
}

@keyframes cloud-middle-frames {
  from { transform: translateX(-$moon-space-dimension); }
  to { transform: translateX($moon-space-dimension); }
}

.cloud-bottom {
  bottom: 175px;
  width: 180px;
  animation: 6s linear infinite cloud-bottom-frames;
  transform: rotate(45deg);
}

@keyframes cloud-bottom-frames {
  from { transform: translateX(-180px); }
  to { transform: translateX($moon-space-dimension); }
}

.phase-container {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $moon-space-dimension;

  svg.waning {
    transform: scaleX(-1);
  }
}

.sky-lines {
  position: relative;
  bottom: $moon-space-dimension;
  height: calc(#{$moon-space-dimension} + 2px);
  margin: auto;
  background-color: $twenty-five-kelly-green-paper;
}

.sky-line {
  display: flex;
  height: 2px;
  margin-bottom: 2px;

  &.twenty-five {

    .sky-segment {
      width: 2px;
      background-color: $twenty-five-kelly-green-ink;

      &.star {
        opacity: 0;
      }

      &.twinkle {
        animation-name: opacity-cycle;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;

        @keyframes opacity-cycle {
          from { opacity: 1; }
          to { opacity: 0; }
        }

        &.fade-1 {
          animation-duration: .5s;
        }

        &.fade-2 {
          animation-duration: .75s;
        }

        &.fade-3 {
          animation-duration: 1s;
        }

        &.fade-4 {
          animation-duration: 1.5s;
        }

        &.fade-5 {
          animation-duration: 2s;
        }
      }
    }
  }
}

.moon-info {
  position: relative;
  bottom: $moon-space-dimension;
  margin-top: .75rem;
  user-select: none;
}

.date-incrementor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.selected-phase-date {
  width: $moon-space-dimension * .66;
  text-align: center;
  line-height: normal;

  .day-month-date {
    font-size: 1.25rem;
  }

  .time-zone {
    font-size: .875rem;
  }
}

.increment-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: $border-width-medium solid $sixty-seven-percent-gray;
  border-radius: 50%;
  background-color: $white;

  &.is-not-visible {
    visibility: hidden;
  }

  &:hover {
    background-color: $twenty-percent-gray;
  }

  svg.back {
    transform: rotate(180deg);
  }
}

.info-for-display {
  margin-top: .5rem;
  text-align: center;
}

.lunar-feature-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;

  .lunar-feature-button {
    margin: .25rem;
  }
}

.lunar-feature-button {
  padding: .25rem .75rem;
  border: $border-width-medium solid $sixty-seven-percent-gray;
  border-radius: $border-radius-medium;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: .75rem;
  text-decoration: none;
  line-height: normal;
  background-color: $sixty-seven-percent-gray;
  color: $white;

  // &.is-not-visible {
  //   visibility: hidden;
  // }

  &.selected,
  &:hover {
    color: $sixty-seven-percent-gray;
    background-color: $white;
  }

  &.active {

    &.twenty-four {
      border-color: $twenty-four-plum-paper;
    }

    &.twenty-five {
      border-color: $twenty-five-kelly-green-paper;
    }
  }
}

.play-year-button-container {
  display: flex;
  justify-content: center;
  margin-top: .5rem;
}

.info-modal-container {
  position: relative;
  bottom: 44.5rem;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.info-modal {
  width: 13rem;
  border-radius: $border-radius-medium;
  background-color: $white;
  opacity: .95;
}

.info-modal-header {
  display: flex;
  justify-content: flex-end;
}

.info-modal-close {
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  margin: .625rem .625rem .125rem;
  background-color: $white;
  color: $eighty-percent-gray;
}

.info-modal-body {
  padding: 0 1.125rem .25rem;
}

.info-modal-text {
  margin-bottom: .5rem;
}

.info-modal-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: .5rem;

  button {
    margin: .25rem auto;
  }
}
